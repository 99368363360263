@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #001324;
  color: white;
}

.animate__fadeIn {
  animation: fadeIn 1s ease forwards;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  @apply 2xl:text-[56px] sm:text-[30px] text-[25px];
}

h2 {
  @apply 2xl:text-[35px] sm:text-[30px] text-[20px];
}

h3 {
  @apply text-[16px] font-medium;
}

p {
  @apply 2xl:text-[16px] sm:text-[18px] text-[12px] font-extralight;
}

a {
  @apply 2xl:text-[16px] sm:text-[18px] text-[12px] font-extralight hover:text-[#ed5934];
}

span {
  @apply text-xl;
}

small {
  @apply sm:text-base text-xs;
}
